import { default as resultsJmQaHUaufnMeta } from "/usr/src/app/pages/admin/results.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as resultRXNKHYxfMUMeta } from "/usr/src/app/pages/result.vue?macro=true";
import { default as sign_45in7sEcJLcQCLMeta } from "/usr/src/app/pages/sign-in.vue?macro=true";
export default [
  {
    name: "results",
    path: "/admin/results",
    meta: resultsJmQaHUaufnMeta || {},
    component: () => import("/usr/src/app/pages/admin/results.vue").then(m => m.default || m)
  },
  {
    name: "main",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/usr/src/app/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "signIn",
    path: "/sign-in",
    meta: sign_45in7sEcJLcQCLMeta || {},
    component: () => import("/usr/src/app/pages/sign-in.vue").then(m => m.default || m)
  }
]