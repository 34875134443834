import type { RuntimeConfig } from "@nuxt/schema";
import axios from "axios";
import { getApiBaseUrl, handleErrors } from "~/api/common/common";
import { cookieUtils } from "~/utils/cookies";
import { redirectAxiosInterceptor } from "~/utils/redirectAxiosInterceptor";

export const adminApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    baseURL: getApiBaseUrl(config) + "/admin",
    withCredentials: true,
    validateStatus: (status) => status !== 401,
  });

  cookieUtils.addAxiosBaseCookiesInterceptors(axiosBase);
  redirectAxiosInterceptor(axiosBase, config);

  return {
    async getResults() {
      const route = useRoute();
      const token = route.query.token;

      const { data: payload } = await axiosBase.get("/results", {
        params: { token },
      });

      return handleErrors(payload);
    },
    async getResultsByFullName(params: { fullName: string }) {
      const route = useRoute();
      const token = route.query.token;

      const { data: payload } = await axiosBase.get("/resultsByFullName", {
        params: { ...params, token },
      });

      return handleErrors(payload);
    },
    async getResultsByPhoneNumber(params: { phoneNumber: string }) {
      const route = useRoute();
      const token = route.query.token;

      const { data: payload } = await axiosBase.get("/resultsByPhoneNumber", {
        params: { ...params, token },
      });

      return handleErrors(payload);
    },
  };
};
