import { userApi } from "~/api/user.api";
import { sessionApi } from "~/api/session.api";
import { resultApi } from "~/api/result.api";
import { adminApi } from "~/api/admin.api";

export const useApi = () => {
  const config = useRuntimeConfig();

  return {
    userApi: userApi(config),
    sessionApi: sessionApi(config),
    resultApi: resultApi(config),
    adminApi: adminApi(config),
  };
};

export type Api = ReturnType<typeof useApi>;
