import { defineStore } from "pinia";
import type { IUserDataJwtPayload, JwtUserData } from '~/types/auth';
import { mockUserDataJwtPayload } from '~/mocks/auth.mock';

interface State {
  user: IUserDataJwtPayload | null | Partial<IUserDataJwtPayload>;
}

const isLocalMode = false;

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    user: null,
  }),
  getters: {
    isAuthenticated: state => !!state.user
  },
  actions: {
    async getUser() {
      const api = useApi()
      if (isLocalMode) {
        console.log('-- Local mode is running! --')
        this.setUser(mockUserDataJwtPayload)
        return;
      }

      const userData = await api.userApi.getMe()
      const jwtUser = userData.value?.user as JwtUserData
      if (!jwtUser) {
        this.user = null
        return;
      }
      this.setUser({
        userId: jwtUser.userId,
        companyId: jwtUser.id,
        username: jwtUser.phoneNumber,
      });
      const response = await api.userApi.getUserFullName(this.user!.userId as string)
      this.user!.firstName = response.value?.firstName
      this.user!.lastName = response.value?.lastName
    },
    setUser (user: IUserDataJwtPayload | Partial<IUserDataJwtPayload>) {
      this.user = user;
    }
  },
});
